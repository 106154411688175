import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Address, Entity, Phone, validatePhone } from '@debbie/common/dist'
import { getCollectorOnCase } from '@debbie/common/dist/cases'
import {
  getPaymentOptions,
  PaymentOptionType as PaymentGateway,
} from '@debbie/common/dist/collectors'
import { PaymentOption as PaymentOptionType } from '@debbie/common/dist/collectors'
import helpers from '@debbie/common/dist/economy/helpers'
import {
  Entry,
  Stage,
  getStage,
  signAllEntries,
  getEntriesWithState,
  CheckedEntries,
  getLastFreezing,
  useAftagenumre,
  useAllOrNone,
  useFreezingPeriodStart,
  useFreezingPeriodStartSelector,
} from '@debbie/common/dist/freezing-system'
import { Lang } from '@debbie/common/dist/lang'
import { c } from '@debbie/cortex/dist'
import { selectCaseVouchers } from '@debbie/cortex/dist/case-vouchers/selectors'
import {
  selectCaseById,
  selectCaseError,
} from '@debbie/cortex/dist/cases/selectors'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
// @ts-ignore TODO This is deprecated, so no need to fix
import { selectAccount } from '@debbie/cortex/dist/economy/selectors'
import { selectFreezingSystem } from '@debbie/cortex/dist/freezing-system/selectors'
import { selectMe } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  joinCaseWatchRoom,
  leaveCaseWatchRoom,
} from '@debbie/cortex/dist/socket'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import {
  Content,
  PageBase,
  Panel,
  PlaceholderLoader,
  Badge,
  Table,
  Select,
} from '@debbie/iris/dist/components'
import CaseVouchers from '@debbie/iris/dist/components/economy/case-vouchers/CaseVouchers'
import { Row, Col, Container } from '@debbie/iris/dist/components/Grid'
import CheckCircle from '@debbie/iris/dist/components/Icons/CheckCircle'
import Mail from '@debbie/iris/dist/components/Icons/Mail'
import PhoneIcon from '@debbie/iris/dist/components/Icons/Phone'
import CustomerInformation from '@debbie/iris/dist/components/Indefrysning/CustomerInformation'
import EntrySelector from '@debbie/iris/dist/components/Indefrysning/EntrySelector'
import FreezeButton from '@debbie/iris/dist/components/Indefrysning/FreezeButton'
import LanguageInput from '@debbie/iris/dist/components/LanguageInput'
import PaymentOption from '@debbie/iris/dist/components/Pay/PaymentOption'
import THEME, { updateTheme } from '@debbie/iris/dist/theme'

import { APIBASE } from '../../../config'
import { Footer, Info, Header, Intro } from '../CaseStyles'

interface Props {
  paid?: boolean
}

enum Step {
  Enrol,
  Receipt,
}

interface ProfileData {
  status: 'not-loaded' | 'loading' | 'loaded'
  name: string | null
  email: string | null
  phone: Phone | null
  address: Address | null
  isCompany: boolean | null
  cvr: string | null
  cpr: string | null
}

const initialProfile = {
  name: null,
  email: null,
  phone: null,
  address: {
    address: null,
    zipcode: null,
    city: null,
    country: null,
  },
  isCompany: null,
  cvr: null,
  cpr: null,
}

const Indefrysning = (props: Props) => {
  const params = useParams<{ id: string }>()
  const id = params.id

  const theme = useSelector(() => THEME)
  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const tenant = useSelector(selectTenant)
  const caseError = useSelector((s: RootState) => selectCaseError(s, id))
  const lang = useSelector((s: RootState) => s.lang)
  const currentLang = useSelector((s: RootState) => s.currentLang)
  const me = useSelector(selectMe)
  const freezingSystem = useSelector(
    (s: RootState) => singleCase && selectFreezingSystem(s, singleCase.caseId),
  )
  const account = useSelector(
    (s: RootState) =>
      singleCase &&
      selectAccount(s, `${singleCase.tenantId}${singleCase.caseId}`),
  )
  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const caseVouchers = useSelector(
    (s: RootState) => singleCase && selectCaseVouchers(s, singleCase.caseId),
  )

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )

  useEffect(() => {
    if (singleCase) c.creditors.get(singleCase.creditorId)
  }, [singleCase])

  useEffect(() => {
    if (singleCase && creditor)
      c.collectors.getById(
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      )
  }, [creditor, singleCase])

  useEffect(() => {
    if (collector) {
      updateTheme(collector.theme)
    }
  }, [collector])

  const [step, setStep] = useState(Step.Enrol)
  const [previousId, setPreviousId] = useState<null | string>(null)

  const [profileData, setProfileData] = useState<ProfileData>({
    ...initialProfile,
    status: 'not-loaded',
  })

  const [checkedEntries, setCheckedEntries] = useState<CheckedEntries>({})
  const [entries, setEntries] = useState<null | Entry[]>(null)
  const [stage, setStage] = useState<null | Stage>(null)
  const [periodStart, setPeriodStart] = useState<string>('')

  useEffect(() => {
    if (freezingSystem) {
      const _entries = getEntriesWithState(
        freezingSystem.entries,
        freezingSystem.freezings,
      )

      setEntries(_entries)

      const _stage = getStage(_entries)

      setStage(_stage)

      const _checkedEntries = [Stage.AllSignedOff, Stage.AllSignedOn].includes(
        _stage,
      )
        ? signAllEntries(_entries, Stage.AllSignedOff === _stage)
        : signAllEntries(_entries)

      setCheckedEntries(_checkedEntries)
    }
  }, [freezingSystem])

  useEffect(() => {
    if (previousId) {
      leaveCaseWatchRoom(`${previousId}`)
      setPreviousId(id)
    }

    if (!singleCase) {
      c.cases.getById(id)
    }

    joinCaseWatchRoom(`${id}`)

    return () => leaveCaseWatchRoom(`${id}`)
  }, [id])

  useEffect(() => {
    if (singleCase && customer && profileData.status === 'not-loaded') {
      getProfileData()
    }
  }, [singleCase, customer])

  useEffect(() => {
    if (singleCase) {
      c.interactions.get(Entity.Case, singleCase.caseId)
    }
  }, [singleCase])

  useEffect(() => {
    if (singleCase) {
      c.customers.getById(singleCase.customerId)
    }
  }, [singleCase])

  useEffect(() => {
    if (singleCase && !freezingSystem) {
      c.freezingSystem.get(singleCase.caseId)
    }
  }, [singleCase])

  const getProfileData = () => {
    const debtor =
      me && customer && customer.users.find((user) => user.userId === me.id)

    if (debtor) {
      setProfileData({
        status: 'loaded',
        email: debtor.details.email,
        name: debtor.details.name,
        phone: debtor.details.phone,
        address: debtor.details.address,
        isCompany: singleCase.isCompany,
        cvr: debtor.details.cvr,
        cpr: debtor.details.cpr,
      })
    } else if (profileData.status !== 'loading') {
      setProfileData({
        ...profileData,
        status: 'loading',
      })
    }
  }

  if (!singleCase) {
    return (
      <Content fullHeight>
        <Container style={{ maxWidth: '600px' }}>
          <Row>
            <Col size="md" width="12" style={{ paddingTop: '80px' }}>
              <PlaceholderLoader style={{ marginTop: '30px' }} />
              <PlaceholderLoader width="40%" style={{ marginTop: '30px' }} />
              <PlaceholderLoader width="60%" style={{ marginTop: '30px' }} />
            </Col>
          </Row>
        </Container>
      </Content>
    )
  }

  if (caseError) {
    return (
      <PageBase>
        <Panel>{caseError}</Panel>
      </PageBase>
    )
  }

  if (singleCase && singleCase.deleted) {
    return (
      <PageBase>
        <Panel>{lang.cases.caseHasBeenDeleted}</Panel>
      </PageBase>
    )
  }

  if (!collector) return null

  const paymentOptions: PaymentOptionType[] | null =
    collector &&
    collector.paymentOptions &&
    account &&
    account.currency &&
    getPaymentOptions(collector.paymentOptions, account.currency).filter(
      (option) => {
        if (
          [PaymentGateway.REEPAY, PaymentGateway.FARPAY].includes(option.type)
        ) {
          return option.isActive
        }
        return true
      },
    )

  const lastFreezing =
    freezingSystem &&
    freezingSystem.freezings &&
    getLastFreezing(freezingSystem.freezings)

  const _useAftagenumre = singleCase && useAftagenumre(singleCase.tenantId)
  const allOrNone = singleCase && useAllOrNone(singleCase.tenantId)

  return (
    <div>
      <Content fullHeight>
        <Container style={{ maxWidth: '600px' }}>
          <Row>
            <Col size="md" width="12">
              {collector ? (
                collector.logo ? (
                  <p style={{ textAlign: 'left', marginTop: '2em' }}>
                    <img
                      width="150px"
                      alt={collector.logo.fileName}
                      src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                    />
                  </p>
                ) : (
                  <span>{collector.name}</span>
                )
              ) : null}
            </Col>
            <Col size="md" width="12">
              <Panel
                bodyStyle={{
                  boxShadow: '0 20px 70px -30px rgb(0 0 0 / 28%)',
                }}
              >
                {(() => {
                  switch (step) {
                    case Step.Receipt:
                      return (
                        <div>
                          <CheckCircle
                            style={{
                              height: '80px',
                              width: '80px',
                              display: 'block',
                              margin: '40px auto',
                            }}
                          />
                          <Badge theme={theme.COLOR_ACCENT}>Kvittering</Badge>
                          <Header
                            style={{
                              marginTop: '0.8em',
                            }}
                          >
                            Vi har modtaget din registrering
                          </Header>
                          <p>
                            Vi har modtaget din registrering vedr.
                            indefrysningsordningen, og vi har tilsendt dig en
                            kvittering.
                          </p>
                          {lastFreezing && lastFreezing.entries.length > 0 && (
                            <Table zebra>
                              <tr>
                                <th>
                                  {_useAftagenumre
                                    ? 'Aftagenummer'
                                    : 'Aftalenummer'}
                                </th>
                                <th></th>
                              </tr>
                              {lastFreezing.entries.map((entry) => (
                                <tr key={entry.entryId}>
                                  <td>
                                    <b>{entry.entryId}</b>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {entry.signedOn ? 'Tilmeldt' : 'Afmeldt'}
                                  </td>
                                </tr>
                              ))}
                            </Table>
                          )}
                        </div>
                      )
                    case Step.Enrol:
                      return (
                        <div>
                          {singleCase && (
                            <Intro>
                              {me && (
                                <h1
                                  style={{
                                    fontSize: '1.5em',
                                  }}
                                >
                                  Hej{' '}
                                  <span>
                                    {me.firstName} {me.lastName}
                                  </span>
                                </h1>
                              )}
                            </Intro>
                          )}

                          <div>
                            <Header>
                              {(() => {
                                switch (stage) {
                                  case Stage.AllSignedOff:
                                    return 'Tilmelding til indefrysningsordningen'
                                  case Stage.AllSignedOn:
                                    return 'Afmelding af indefrysningsordningen'
                                  case Stage.Mixed:
                                    return 'Til- og afmelding af indefrysningsordningen'
                                }
                              })()}
                            </Header>
                            {(
                              singleCase.isCompany
                                ? tenant.settings.freezingSystemTextCompany
                                : tenant.settings.freezingSystemTextPrivate
                            ) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: singleCase.isCompany
                                    ? tenant.settings.freezingSystemTextCompany
                                    : tenant.settings.freezingSystemTextPrivate,
                                }}
                              />
                            ) : (
                              <>
                                <p>
                                  For at skabe tryghed om størrelsen på
                                  energiregningen har Folketinget indgået en
                                  bred aftale om en midlertidig og frivillig
                                  indefrysningsordning for en del af
                                  husholdningernes og virksomhedernes udgifter
                                  til el, gas og fjernvarme.
                                </p>
                                <p>
                                  Du kan læse mere om indefrysningsordningen{' '}
                                  <a
                                    href="https://www.borger.dk/miljoe-og-energi/Energi/indefrysning-af-energiudgifter"
                                    target="blanc"
                                    style={{ color: 'inherit' }}
                                  >
                                    her
                                  </a>
                                  .
                                </p>
                              </>
                            )}
                            {singleCase &&
                              useFreezingPeriodStart(singleCase.tenantId) && (
                                <p>
                                  Periode start:{' '}
                                  <b>
                                    {moment()
                                      .startOf('month')
                                      .format('DD/MM/YYYY')}
                                  </b>
                                </p>
                              )}
                            {singleCase &&
                              useFreezingPeriodStartSelector(
                                singleCase.tenantId,
                              ) && (
                                <>
                                  <Select
                                    onChange={(e) =>
                                      setPeriodStart(e.target.value)
                                    }
                                    value={periodStart}
                                  >
                                    <option value="">- Vælg dato -</option>
                                    {(() => {
                                      const dates = []

                                      const startDate = moment(
                                        '2022-11-01 20:00:00+00',
                                      ).startOf('month')

                                      for (let i = 0; i < 12; i++) {
                                        dates.push(startDate.clone())

                                        startDate.add(1, 'month')
                                      }

                                      return dates
                                        .filter((date) => {
                                          if (stage == Stage.AllSignedOn) {
                                            if (
                                              date.isSameOrAfter(
                                                moment().startOf('month'),
                                              )
                                            ) {
                                              return date.isSame(
                                                moment().startOf('month'),
                                              )
                                                ? moment()
                                                    .endOf('month')
                                                    .isAfter(moment())
                                                : true
                                            }
                                          } else {
                                            return true
                                          }
                                        })
                                        .map((date) => {
                                          const val = date.format('YYYY-MM-DD')
                                          return (
                                            <option key={val} value={val}>
                                              Fra {date.format('LL')}
                                            </option>
                                          )
                                        })
                                    })()}
                                  </Select>
                                </>
                              )}
                          </div>
                          {entries && singleCase && (
                            <EntrySelector
                              entries={entries}
                              setCheckedEntries={setCheckedEntries}
                              checkedEntries={checkedEntries}
                              allOrNone={allOrNone}
                              singleCase={singleCase}
                            />
                          )}
                          <Header style={{ fontSize: '1em' }}>
                            {lang.debtorWebHome.yourInfo}
                          </Header>
                          {profileData.status === 'loaded' && (
                            <CustomerInformation
                              info={profileData}
                              onChange={(change) =>
                                setProfileData({ ...profileData, ...change })
                              }
                            />
                          )}
                          {creditor && creditor.debtorFAQ && (
                            <>
                              <Header>{lang.debtorWebHome.faq}</Header>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: creditor.debtorFAQ,
                                }}
                              ></div>
                            </>
                          )}
                          <br />
                          {singleCase &&
                            checkedEntries &&
                            entries &&
                            customer && (
                              <FreezeButton
                                singleCase={singleCase}
                                customer={customer}
                                onFinish={async (changes, res) => {
                                  const id = crypto.randomUUID()

                                  localStorage.setItem(
                                    `freeze_${id}`,
                                    JSON.stringify({
                                      collector,
                                      date: moment().format(),
                                      changes,
                                      creditorId: singleCase.creditorId,
                                      tenantId: singleCase.tenantId,
                                      agreementFileId:
                                        res && res.agreementFileId,
                                    }),
                                  )

                                  c.auth.logout(`/confirmation/${id}`)
                                }}
                                checkedEntries={checkedEntries}
                                entries={entries}
                                profileData={profileData}
                                periodStart={
                                  useFreezingPeriodStartSelector(
                                    singleCase.tenantId,
                                  )
                                    ? periodStart
                                    : undefined
                                }
                              />
                            )}
                        </div>
                      )
                  }
                })()}
              </Panel>
              {account && helpers.overview.balance(account.vouchers) < -500 && (
                <Panel header={'Indefrosset forbrug'}>
                  {singleCase && (
                    <CaseVouchers
                      caseVouchers={caseVouchers.map((cv) => {
                        return {
                          ...cv,
                          children: [],
                        }
                      })}
                      singleCase={singleCase}
                    />
                  )}
                  <Header>
                    Indfri dit indefrossede forbrug og undgå yderligere renter
                  </Header>
                  <Pay>
                    {paymentOptions ? (
                      paymentOptions.map((paymentOption) => (
                        <PaymentOption
                          key={JSON.stringify(paymentOption)}
                          paymentOption={paymentOption}
                          case={singleCase}
                          collector={collector}
                          caseVouchers={caseVouchers}
                          isPaymentPlan={false}
                          paymentOptionsCount={
                            paymentOptions ? paymentOptions.length : 0
                          }
                          avoidPartialPayments
                        />
                      ))
                    ) : (
                      <PlaceholderLoader />
                    )}
                  </Pay>
                </Panel>
              )}
              <Header>{lang.debtorWebHome.contactUs}</Header>
              {collector && (
                <Row>
                  <Col size="md" width="8">
                    <p>Du er altid velkommen til at kontakte os.</p>
                    <Info>
                      {collector.email && (
                        <>
                          <Mail /> {collector.email}
                        </>
                      )}
                      {validatePhone(collector.phone) && (
                        <>
                          <PhoneIcon
                            style={{ marginLeft: `${THEME.GRID_SPACING}px` }}
                          />{' '}
                          +{collector.phone.locale} {collector.phone.number}
                        </>
                      )}
                    </Info>
                  </Col>
                  <Col size="md" width="4">
                    {collector.logo ? (
                      <p style={{ textAlign: 'center', margin: '-5px 0' }}>
                        <img
                          width="100%"
                          style={{ maxWidth: '180px' }}
                          alt={collector.logo.fileName}
                          src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                        />
                      </p>
                    ) : (
                      <span>{collector.name}</span>
                    )}
                  </Col>
                </Row>
              )}
              <br />
              <br />
            </Col>
          </Row>
        </Container>
        <Footer>
          {collector && (
            <ul>
              <li>
                <a onClick={() => c.auth.logout()}>
                  {lang.debtorWebHome.logout}
                </a>
              </li>
              {collector.privacyPolicy && (
                <li>
                  <a target="_blanc" href={collector.privacyPolicy}>
                    {lang.debtorWebHome.privacyPolicy}
                  </a>
                </li>
              )}
              {collector.businessTerms && (
                <li>
                  <a target="_blanc" href={collector.businessTerms}>
                    {lang.debtorWebHome.businessTerms}
                  </a>
                </li>
              )}
              <li>{collector.name}</li>
              {collector.centralRegisterId && (
                <li>CVR: {collector.centralRegisterId}</li>
              )}
              {collector.address &&
                collector.address.address &&
                collector.address.zipcode &&
                collector.address.city && (
                  <li>
                    {collector.address.address}, {collector.address.zipcode}{' '}
                    {collector.address.city}
                  </li>
                )}
            </ul>
          )}
          <div
            style={{
              textAlign: 'center',
              marginTop: '2em',
            }}
          >
            <LanguageInput
              language={currentLang}
              onChange={(lang) => c.lang.setLang(lang)}
              availableLanguages={[Lang.da, Lang.en]}
            />
          </div>
        </Footer>
      </Content>
    </div>
  )
}

export default Indefrysning

const Pay = styled.div`
  .providers {
    padding: ${THEME.GRID_SPACING}px 0;
    text-align: center;

    img {
      max-width: 80%;
      max-height: 20px;
    }
  }
`

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// @ts-ignore
import styled from 'styled-components'

import { getCollectorOnCase } from '@debbie/common/dist/cases'
import {
  PaymentPlanStatus,
  Plan,
  getCurrentPaymentPlan,
} from '@debbie/common/dist/economy/payment-plans'
import { PaymentMethodStatus } from '@debbie/common/dist/payment-methods'
import { c, h } from '@debbie/cortex/dist'
import * as paymentPlansAPI from '@debbie/cortex/dist/api/payment-plans'
import { selectCaseVouchers } from '@debbie/cortex/dist/case-vouchers/selectors'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { selectMe, selectRole } from '@debbie/cortex/dist/me/selectors'
import { selectPaymentPlans } from '@debbie/cortex/dist/paymentPlans/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  Content,
  Panel,
  PlaceholderLoader,
  Progress,
} from '@debbie/iris/dist/components'
import { Col, Row, Container } from '@debbie/iris/dist/components/Grid'
import ArrowLeft from '@debbie/iris/dist/components/Icons/ArrowLeft'

import { Step, Info } from '../../components'
import { Steps } from '../../components/Step'
import { APIBASE } from '../../config'

const CreatePaymentPlan = () => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const lang = useSelector((s: RootState) => s.lang)
  const me = useSelector(selectMe)
  const meRole = useSelector(selectRole)
  const isCaseLoading = useSelector((s: RootState) => h.isLoading(s.cases[id]))
  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const paymentPlans = useSelector(
    (s: RootState) => singleCase && selectPaymentPlans(s, singleCase.caseId),
  )

  const _caseVouchers = useSelector(
    (s: RootState) => singleCase && selectCaseVouchers(s, singleCase.caseId),
  )

  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  useEffect(() => {
    if (singleCase) c.creditors.get(singleCase.creditorId)
  }, [singleCase])

  useEffect(() => {
    if (singleCase && !_caseVouchers) {
      c.caseVouchers.getByCaseId(singleCase.caseId)
    }
  }, [singleCase])

  useEffect(() => {
    if (singleCase && creditor)
      c.collectors.getById(
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      )
  }, [creditor, singleCase])

  useEffect(() => {
    if (singleCase && !paymentPlans) {
      c.paymentPlans.getByCaseId(singleCase.caseId)
    }
  }, [singleCase])

  const paymentPlan: Plan | null = getCurrentPaymentPlan(paymentPlans)

  let STEP: Steps = Steps.SELECTPLAN

  if (!paymentPlan) {
    STEP = Steps.SELECTPLAN
  } else if (
    paymentPlan.plan.length !== 0 &&
    !paymentPlan.documentSignedAt &&
    paymentPlan.status === PaymentPlanStatus.CREATED
  ) {
    STEP = Steps.SIGNING
  } else if (
    paymentPlan.paymentMethodStatus !== PaymentMethodStatus.SAVED &&
    paymentPlan.plan.length !== 0
  ) {
    STEP = Steps.CONNECTCARD
  } else {
    STEP = Steps.CONGRATULATIONS
  }

  useEffect(() => {
    loadCase(id)
  }, [id])

  const loadCase = async (id) => {
    if (!isCaseLoading) c.cases.getById(id)
  }

  return (
    <div>
      <Content fullHeight>
        <PageBase>
          <Container>
            {singleCase && (
              // @ts-ignore
              <Link
                to={`/cases/${singleCase.id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <p
                  style={{
                    color: 'grey',
                    fontSize: '.8em',
                  }}
                >
                  <b>
                    <ArrowLeft style={{ margin: '-.2em 0px' }} />{' '}
                    {lang.debtorWebPaymentPlans.backToCase}
                  </b>
                </p>
              </Link>
            )}
            <Row>
              <StyledCol size="md" width="3">
                <Panel
                  header={
                    singleCase
                      ? `${lang.debtorWebPaymentPlans.paymentPlanOn} #${singleCase.id}`
                      : ''
                  }
                >
                  <div>
                    <br />
                    <div>
                      <Progress
                        items={[]
                          .concat(
                            !paymentPlan || paymentPlan?.createdByMe
                              ? [
                                  {
                                    title:
                                      lang.debtorWebPaymentPlans.selectPlan,
                                    active: STEP === Steps.SELECTPLAN,
                                    onClick:
                                      paymentPlan &&
                                      paymentPlan.status !==
                                        PaymentPlanStatus.ACTIVE &&
                                      paymentPlan.createdByMe
                                        ? async () => {
                                            try {
                                              await paymentPlansAPI.del(
                                                paymentPlan.paymentPlanId,
                                              )
                                              await c.paymentPlans.getByCaseId(
                                                singleCase.caseId,
                                              )
                                            } catch (error) {
                                              console.error(error)
                                              alert(
                                                'An error occured. Please try again.',
                                              )
                                            }
                                          }
                                        : undefined,
                                  },
                                ]
                              : [],
                          )
                          .concat([
                            {
                              title: lang.debtorWebPaymentPlans.sign,
                              active: STEP === Steps.SIGNING,
                            },
                            {
                              title: lang.debtorWebPaymentPlans.paymentMethod,
                              active: STEP === Steps.CONNECTCARD,
                            },
                            {
                              title: lang.debtorWebPaymentPlans.approval,
                              active: STEP === Steps.CONGRATULATIONS,
                            },
                          ])}
                      />
                    </div>
                    <br />
                    <Info
                      caseVouchers={_caseVouchers}
                      singleCase={singleCase}
                      me={me}
                    />
                  </div>
                  {!(singleCase && paymentPlans) && <PlaceholderLoader />}
                </Panel>
                {collector && (
                  <p style={{ textAlign: 'center' }}>
                    {collector && collector.logo ? (
                      <img
                        height="35px"
                        alt={collector.logo.fileName}
                        src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                      />
                    ) : (
                      <span>{collector.name}</span>
                    )}
                  </p>
                )}
              </StyledCol>
              <Col size="md" width="9">
                {singleCase && _caseVouchers && paymentPlans && (
                  <Step
                    singleCase={singleCase}
                    paymentPlan={paymentPlan}
                    caseVouchers={_caseVouchers}
                    meRole={meRole}
                    step={STEP}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </PageBase>
      </Content>
    </div>
  )
}

export default CreatePaymentPlan

const StyledCol = styled(Col)`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`

import React from 'react'
import { useSelector } from 'react-redux'

import * as _collectors from '@debbie/common/dist/collectors'
import { c } from '@debbie/cortex/dist'
import { deleteCard } from '@debbie/cortex/dist/api/payment-plans'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Button, Panel } from '@debbie/iris/dist/components'

import EntriesList from './EntriesList'

const CongratulationsStep = ({ currency, caseId, paymentPlan }) => {
  const lang = useSelector((s: RootState) => s.lang)

  return (
    <div>
      <Panel header={lang.debtorWebPaymentPlans.approval}>
        <p>{lang.debtorWebPaymentPlans.approvalText}</p>
      </Panel>
      <Panel>
        <p>{lang.debtorWebPaymentPlans.deleteCardToChange}</p>
        <Button
          onClick={async (e) => {
            await deleteCard(paymentPlan.paymentPlanId)
            await c.paymentPlans.getByCaseId(caseId)
          }}
        >
          {lang.debtorWebPaymentPlans.deleteCard}
        </Button>
      </Panel>
      <Panel>
        <EntriesList paymentPlan={paymentPlan} currency={currency} />
      </Panel>
    </div>
  )
}

export default CongratulationsStep

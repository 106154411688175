import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  isFjernvarmevaerk,
  useAftagenumre,
} from '@debbie/common/dist/freezing-system'
import {
  Badge,
  Content,
  File,
  Panel,
  Table,
} from '@debbie/iris/dist/components'
import { Container } from '@debbie/iris/dist/components/Grid'
import CheckCircle from '@debbie/iris/dist/components/Icons/CheckCircle'
import THEME, { updateTheme } from '@debbie/iris/dist/theme'

import { APIBASE } from '../config'

const FreezingSystemConfirmation = (props: {}) => {
  const { id } = useParams<{ id: string }>()
  const theme = useSelector(() => THEME)
  const [_, setRerender] = useState(0)

  const _data = id && localStorage.getItem(`freeze_${id}`)

  if (!_data) return null

  const data = _data && JSON.parse(_data)

  const { collector, changes, agreementFileId, creditorId, tenantId } = data

  const _useAftagenumre = tenantId && useAftagenumre(tenantId)

  useEffect(() => {
    if (collector && collector.theme) {
      updateTheme(collector.theme)
      setRerender(Math.random())
    }
  }, [collector])

  return (
    <div>
      <Content fullHeight>
        <Container style={{ maxWidth: '600px' }}>
          {collector ? (
            collector.logo ? (
              <p style={{ textAlign: 'left', marginTop: '2em' }}>
                <img
                  width="150px"
                  alt={collector.logo.fileName}
                  src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                />
              </p>
            ) : (
              <span>{collector.name}</span>
            )
          ) : null}
          <Panel
            bodyStyle={{
              boxShadow: '0 20px 70px -30px rgb(0 0 0 / 28%)',
            }}
          >
            <div>
              <CheckCircle
                style={{
                  height: '80px',
                  width: '80px',
                  display: 'block',
                  margin: '40px auto',
                }}
              />
              <Badge theme={theme.COLOR_ACCENT}>Kvittering</Badge>
              <h1
                style={{
                  marginTop: '0.8em',
                }}
              >
                Vi har modtaget din registrering
              </h1>
              <p>
                Vi har modtaget din registrering vedrørende
                indefrysningsordningen, og vi har tilsendt dig en kvittering.
              </p>
              {agreementFileId && (
                <File
                  file={{
                    contentType: 'application/pdf',
                    fileId: agreementFileId,
                    fileName: 'dokument.pdf',
                  }}
                />
              )}
              {changes && changes.length > 0 && (
                <Table zebra>
                  <tr>
                    <th>
                      {isFjernvarmevaerk(tenantId)
                        ? 'Forbrugernummer'
                        : _useAftagenumre
                        ? 'Aftagenummer'
                        : 'Aftalenummer'}
                    </th>
                    <th></th>
                  </tr>
                  {changes.map((entry) => (
                    <tr key={entry.entryId}>
                      <td>
                        <b>{entry.entryId}</b>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {entry.signedOn ? 'Tilmeldt' : 'Afmeldt'}
                      </td>
                    </tr>
                  ))}
                </Table>
              )}
            </div>
          </Panel>
        </Container>
      </Content>
    </div>
  )
}

export default FreezingSystemConfirmation
